import {Alignment, EventType, Fit, Layout, useRive} from "@rive-app/react-canvas";
import rivePTBR from '../../assets/animations/site_provisorio-pt.riv'
import riveEN from '../../assets/animations/site_provisorio-en.riv'
import {useEffect} from "react";
import detectBrowserLanguage from 'detect-browser-language'


const language = detectBrowserLanguage();

const Mobile = (props) => {
    const {rive: backgroundRive, RiveComponent: BackgroundRiveComponent} = useRive({
        src: language==='pat' ? rivePTBR : riveEN,
        stateMachines: "State Machine 1",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.Center
        })
    });


    useEffect(() => {
        if (backgroundRive){
            backgroundRive.on(EventType.RiveEvent, eventReceived);
        }

    }, [backgroundRive]);

    const eventReceived = (event) => {
        if (event.data && event.data.url){
            window.open(event.data.url, event.data.target)
        }
    }


    return (
        <div className="Rive">
            <BackgroundRiveComponent/>
        </div>
    );
}

export default Mobile;
