import './assets/css/App.css';
import Main from './screens/main'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Main/>
      </header>
    </div>
  );
}

export default App;
